/* MAIN WRAPPER DEFAULTS */
main.scrollytell {
  overflow: hidden
}

/* SVG DEFAULTS */
.st0 {
  fill:none;
  stroke:#000000;
  stroke-width:8;
  stroke-miterlimit:10;
}

/* TIMELINE SECTIONS */

/* HEADER CONTAINER */
.header-container {

}

/* HERO CONTAINER */
.hero-container {
  margin: 200px auto 100px auto;
}
.hero-container svg {
  position: relative;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 80%;
}
@media (max-width: 800px) {
  .hero-container svg {
    position: relative;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 80%;
  }
  .hero-container {
    margin: 200px auto 40px auto;
  }
}

/* CONTENT CONTAINER */
.content-container {

}
.content-container section {
  position: relative;
  z-index: 2;
  /* height: 20vh; */
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #e5e5e5;
  color: black;
  margin: 5px auto;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16pt;
  line-height: 34px;
  padding: 20px;
}
.content-container section .section-child {
  width: 100%;
}
.content-container section .section-child > div {
  margin: 0px;
}
.content-container section .section-child .MuiGrid-item {
  text-align: left;
}
.content-container section .section-child .img-wrapper {
  margin: 20px auto;
  max-width: 400px;
}
.content-container section .section-child img {
  width: 100%;
  height: 100%;
}
.content-container section .section-child ul {
  /* padding-inline-start: 20px; */
  text-align: left;
  line-height: normal;
}
.content-container section .section-child ul li {
  font-weight: 200;
  font-size: 14pt;
}
@media (max-width: 800px) {
  .content-container section .section-child ul li {
    font-size: 12pt;
  }
}
.content-container section.sticky {
  font-size: 12pt;
  color: black;
}

/* CONNECTOR CONTAINER */
.connector svg {
  width: 40%;
  margin: 0px auto;
}
@media (max-width: 800px) {
  .connector svg {
    width: 55%;
  }
}

/* FOOTER CONTAINER */
.footer-container svg {
  position: relative;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 80%;
}
.footer-container svg #footer-fork path  {
  width: 100%;
}
.footer-container {
  /* height: 30vh; */
  min-height: 200px;
}
.footer-container h1.footer {
  color: black;
  font-size: 14pt;
}

/* Animated Scroll Down Mouse */
@-webkit-keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
@-moz-keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
@keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
 .scroll-btn {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
  margin-top: 50px;
}
.scroll-btn > * {
	display: contents;
	line-height: 18px;
	font-size: 13px;
	font-weight: normal;
	color: #000000;
	font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
	letter-spacing: 2px;
}
.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *.active {
	color: #000000;
}
.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *:active,
.scroll-btn > *.active {
	opacity: 0.8;
	filter: alpha(opacity=80);
}
.scroll-btn .mouse {
	position: relative;
	display: block;
	width: 35px;
	height: 55px;
	margin: 0 auto 20px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border: 3px solid #000000;
	border-radius: 23px;
}
.scroll-btn .mouse > * {
	position: absolute;
	display: block;
	top: 29%;
	left: 50%;
	width: 8px;
	height: 8px;
	margin: -4px 0 0 -4px;
	background: #000000;
	border-radius: 50%;
	-webkit-animation: ani-mouse 2.5s linear infinite;
	-moz-animation: ani-mouse 2.5s linear infinite;
	animation: ani-mouse 2.5s linear infinite;
}

/* CUSTOM ANIMATIONS */
@keyframes hideshow {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}
#animated-signal-emitting-1 {
  opacity: 0;
  animation: hideshow 1.5s 1.5s ease infinite;
  stroke: #3E78BC;
}
#animated-signal-receiving-footer {
  opacity: 0;
  animation: hideshow 1.5s 1.5s ease infinite;
  stroke: #3E78BC;
}

/* CUSTOM MOBILE BUTTONS */
@media(max-width: 800px) {
  .mobile-fit {
    white-space: break-spaces !important;
    padding: 5px !important;
    min-height: 6rem !important;
  }
  .mobile-fit svg {
    width: 80%;
  }
  .mobile-fit span {
    display: inline-grid !important;
    margin: 10px 10px;
    min-height: 6rem;
  }
  .mobile-fit i.fas {
    margin: 10px 5px;
  }
}

/* STICKY FIXES */
.pin-spacer,
.pin-spacer .sticky {
  z-index: 2000 !important;
}
