body {
  background-color: black;
  // background-image: url('../../img/background.jpeg');
  // background-size: cover;
}
// body::after {
//   content: ""; // ::before and ::after both require content
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-image: linear-gradient(120deg, black, #3b5998);
//   opacity: .2;
// }

._37_FJ,
._ovIFV {
  background-color: transparent;
  border: none;
  text-align: center;
}
._37_FJ input {
  padding: 0.5rem;
  border-radius: 0.5rem;
}
._1YwH3 {
  align-self: center;
}
._1YwH3 button {
  padding: 0.5rem 2rem;
  width: 100%;
  font-weight: bold;
  border-radius: 0.5rem;
  border: 1px solid white;
}
._1YwH3 button:hover {
  background-color: transparent;
  border-color: white;
  color: white;
}
