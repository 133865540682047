.calculator button {
  font-size: 1rem;
  width: 30px;
  border: 1px solid #d6d6d6;
  cursor: pointer;
}

.calculator h2,
.calculator h3 {
  width: 100%;
  color: black;
  font-size: 14pt;
}

.calculator,
.calculator .quote {
  display: flex;
  flex-flow: column;
  width: 100%;
  font-family: sans-serif;
  text-align: center;
}

.calculator .quote {
  position: sticky;
  top: 80px;
  height: auto;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
}

.calculator .quoteTitle,
.calculator .quoteTotal,
.calculator .featuresTitle,
.calculator .resetCalculator {
  width: 80%;
  align-self: center;
  text-align: left;
  background: lightgray;
  padding: 10px;
  margin: 1.75rem auto 0.875rem auto;
  border-radius: 6px;
}
.calculator .quoteTitle,
.calculator .featuresTitle {
  background: none;
}
.calculator .quoteTotal {
  text-align: right;
}
.calculator .currencySpacer {
  width: 20px;
  text-align: left;
  display: inline-block;
}
.calculator .resetCalculator {
  text-align: center;
}

.calculator ul.featuresList {
  width: 88%;
  max-height: 160px;
  text-align: left;
  margin: 20px auto 20px auto;
  overflow-y: scroll;
  display: inline-block;
  list-style-type: disc;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 40px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.calculator ul.featuresList li {
  color: darkgrey;
  display: inline-block;
  width: 25%;
}
.calculator ul.featuresList li(4n) + li {
  clear: both;
}
@media(max-width: 800px) {
  .calculator ul.featuresList {
    width: 80%;
    margin: 0px auto;
    margin-inline-start: 40px;
    padding-inline-start: 0px;
  }
  .calculator ul.featuresList li {
    width: 50%;
  }
  .calculator ul.featuresList li(2n) + li {
    clear: both;
  }
}

.calculator .panel {
  display: flex;
  justify-content: center;
}

.calculator .numbers {
  display: flex;
  flex-flow: wrap;
  flex-wrap: wrap;
  justify-items: stretch;
  width: 100%;
}

.calculator .numbers button {
  width: 20%;
  margin: 2.5%;
  padding: 20px;
}
@media(max-width: 800px) {
  .calculator .numbers button {
    width: 40%;
    margin: 5%;
    padding: 20px;
  }
}

.calculator .numbers button:last-child {
  width: 100%;
}

.calculator .operators {
  display: flex;
  flex-flow: column;
}

.calculator .operators button {
  color: white;
  background: #7f39ff;
  border-left: none;
  border-bottom: 0;
}
